import React, { memo, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Card,
  CardBody,
  CardHeader,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Icon,
  IconButton,
  ListItem,
  ListItemProps,
  Stack,
  Tag,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { MdCopyAll, MdDelete, MdEdit, MdOpenInNew } from "react-icons/md";
import { QuestWithId } from "../models/api/quest";
import UpdateQuestModal, { repeatPeriods } from "./modals/UpdateQuestModal";
import DeleteQuestModal from "./modals/DeleteQuestModal";
import QuestListItemOverview from "./QuestListItemOverview";
import { User } from "./User";
import { AiOutlineCopy } from "react-icons/ai";
import useQuest from "../hooks/quests/useQuest";
import QuestStatusButton from "../features/quests/QuestStatusButton";
import { formatDate } from "../utils/date";
import ConfirmModal from "./modals/ConfirmModal";
import useArchiveQuest from "../hooks/quests/useArchiveQuest";
import { useQuestsSelectionProvider } from "../context/quests/QuestsSelectionProvider";
import QuestResetButton from "./quests/QuestResetButton";
import useSessionUserId from "../hooks/users/useSessionUserId";
import useUnarchiveQuest from "../hooks/quests/useUnarchiveQuest";
import QuestTogglePlayerVisibilityButton from "./quests/QuestTogglePlayerVisibilityButton";

interface QuestListItemTitleProps {
  questId: string;
  title: string;
}

function QuestListItemTitle({ questId, title }: QuestListItemTitleProps) {
  const { isSelected, toggleSelection } = useQuestsSelectionProvider();

  return (
    <Heading size={"md"}>
      <HStack>
        <Tooltip label={"mark for archive"}>
          <Checkbox isChecked={isSelected(questId)} onChange={() => toggleSelection(questId)} />
        </Tooltip>

        <Text color={"white"} isTruncated>
          {title}
        </Text>
      </HStack>
    </Heading>
  );
}

interface QuestListItemProps extends ListItemProps {
  index: number;
  questId: string;
  version: string;
  onUpdate: (quest: QuestWithId) => void;
  isUpdating: boolean;
  onDelete: (questId: string) => void;
  isDeleting: boolean;
  onDuplicate: (questId: string) => void;
  isDuplicating: boolean;
  openIndexes: number[];
  onToggleOpenIndex: (index: number) => void;
}

const QuestListItem: React.FC<QuestListItemProps> = ({
  index,
  questId,
  version,
  onUpdate,
  isUpdating,
  onDelete,
  isDeleting,
  onDuplicate,
  isDuplicating,
  openIndexes,
  onToggleOpenIndex,
  ...listItemProps
}) => {
  const { sessionUserId } = useSessionUserId();
  const { isLoading, quest } = useQuest(questId, version);

  const toast = useToast();

  const { isOpen: isOpenUpdate, onOpen: onOpenUpdate, onClose: onCloseUpdate } = useDisclosure();
  const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

  const handleOpenUpdateQuestModal = useCallback(() => {
    onOpenUpdate();
  }, [onOpenUpdate]);

  const handleOpenDeleteQuestModal = useCallback(() => {
    onOpenDelete();
  }, [onOpenDelete]);

  const handleUpdateQuest = useCallback(
    (quest: QuestWithId) => {
      onUpdate(quest);

      onCloseUpdate();
    },
    [onUpdate, onCloseUpdate]
  );

  const handleDeleteQuest = useCallback(
    (questId: string) => {
      onDelete(questId);

      onCloseDelete();
    },
    [onDelete, onCloseDelete]
  );

  const handleCopyQuestId = useCallback(async () => {
    await navigator.clipboard.writeText(questId);

    toast({
      title: `Copied ID ${questId} to clipboard`,
      status: "info",
    });
  }, [toast]);

  const { isArchiving, archiveQuest } = useArchiveQuest(questId, quest);
  const { isUnarchiving, unarchiveQuest } = useUnarchiveQuest(questId, quest);

  if (quest == null) {
    return null;
  }

  const {
    name: questName,
    description: questDescription,
    isReady: isQuestReady,
    isArchived,
    isTemplate,
    repeatPeriod,
    authorId,
    createdAt,
    visible,
  } = quest;

  return (
    <>
      <ListItem {...listItemProps}>
        <Card bg={"gray.800"} borderRadius={0}>
          <CardHeader bg={"indigo.600"}>
            <Flex alignItems={"center"} justifyContent={"space-between"}>
              <Stack as={RouterLink} to={questId} target={"_blank"}>
                <QuestListItemTitle questId={questId} title={questName} />

                <HStack>
                  <Text size={"sm"}>created by</Text>
                  <User size={"sm"} userId={authorId} />
                  {createdAt && (
                    <>
                      <Text size={"sm"}>on</Text>
                      <Text size={"sm"}>{formatDate(createdAt)}</Text>
                    </>
                  )}
                </HStack>
              </Stack>

              <HStack>
                <Tooltip label={"copy quest id"}>
                  <IconButton
                    aria-label={"copy quest id"}
                    icon={<Icon as={AiOutlineCopy} />}
                    color={"white"}
                    onClick={handleCopyQuestId}
                  />
                </Tooltip>

                <Tooltip label={"open quest"}>
                  <IconButton
                    aria-label={"open quest"}
                    icon={<Icon as={MdOpenInNew} />}
                    color={"white"}
                    as={RouterLink}
                    to={questId}
                    target={"_blank"}
                  />
                </Tooltip>
              </HStack>
            </Flex>
          </CardHeader>
          <CardBody>
            <Stack>
              <Stack>
                <Text color={"white"}>{questDescription || "No description given"}</Text>

                <Stack alignSelf={"end"}>
                  <QuestStatusButton
                    questId={questId}
                    nodes={quest.data?.nodes}
                    edges={quest.data?.edges}
                    size={"sm"}
                    variant={"outline"}
                  />
                  <HStack alignSelf={"end"}>
                    <Tooltip label={"delete quest"}>
                      <IconButton
                        borderColor={"red.800"}
                        color={"red.800"}
                        variant={"outline"}
                        icon={<Icon as={MdDelete} />}
                        aria-label={"delete quest"}
                        disabled={isDeleting}
                        onClick={handleOpenDeleteQuestModal}
                      />
                    </Tooltip>
                    <Tooltip label={"duplicate quest"}>
                      <IconButton
                        color={"white"}
                        variant={"outline"}
                        icon={<Icon as={MdCopyAll} />}
                        aria-label={"duplicate quest"}
                        disabled={isDuplicating}
                        onClick={() => onDuplicate(questId)}
                      />
                    </Tooltip>
                    <Tooltip label={"update quest"}>
                      <IconButton
                        color={"white"}
                        variant={"outline"}
                        icon={<Icon as={MdEdit} />}
                        aria-label={"update quest"}
                        disabled={isUpdating}
                        onClick={handleOpenUpdateQuestModal}
                      />
                    </Tooltip>

                    <QuestTogglePlayerVisibilityButton questId={questId} />

                    {!isArchived && (
                      <ConfirmModal
                        title={"ARCHIVE QUEST"}
                        color={"white"}
                        onConfirm={archiveQuest}
                        isLoading={isLoading}
                        isDisabled={isArchiving}
                      >
                        <Stack>
                          <Text color={"white"} fontWeight={500}>
                            Are you sure you want to archive this quest?
                          </Text>
                        </Stack>
                      </ConfirmModal>
                    )}

                    {isArchived && (
                      <ConfirmModal
                        title={"UNARCHIVE QUEST"}
                        color={"white"}
                        onConfirm={unarchiveQuest}
                        isLoading={isLoading}
                        isDisabled={isUnarchiving}
                      >
                        <Stack>
                          <Text color={"white"} fontWeight={500}>
                            Are you sure you want to unarchive this quest?
                          </Text>
                        </Stack>
                      </ConfirmModal>
                    )}

                    {sessionUserId && <QuestResetButton questId={questId} userId={sessionUserId} />}
                  </HStack>
                </Stack>
              </Stack>

              <HStack>
                {isTemplate && (
                  <Tag>
                    <Text color={"white"} casing={"uppercase"}>
                      quest template
                    </Text>
                  </Tag>
                )}
                {repeatPeriod !== "none" && (
                  <Tag>
                    <Text color={"white"} casing={"uppercase"}>
                      repeats {repeatPeriods[repeatPeriod ?? "none"]}
                    </Text>
                  </Tag>
                )}
              </HStack>
            </Stack>
          </CardBody>

          <Accordion allowToggle index={openIndexes.includes(index) ? [0] : []}>
            <AccordionItem>
              {({ isExpanded }) => (
                <>
                  <AccordionButton onClick={() => onToggleOpenIndex(index)}>
                    <HStack>
                      <AccordionIcon />

                      <Text color={"white"} casing={"uppercase"} fontWeight={500}>
                        Show {isExpanded ? "Less" : "More"}
                      </Text>
                    </HStack>
                  </AccordionButton>

                  <AccordionPanel borderTopWidth={1}>
                    <QuestListItemOverview quest={quest} />
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          </Accordion>
        </Card>
      </ListItem>

      <UpdateQuestModal isOpen={isOpenUpdate} onClose={onCloseUpdate} quest={quest} onUpdate={handleUpdateQuest} />
      <DeleteQuestModal isOpen={isOpenDelete} onClose={onCloseDelete} quest={quest} onDelete={handleDeleteQuest} />
    </>
  );
};

export default memo(QuestListItem);
